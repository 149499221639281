
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 额外返现记录
 */
export default defineComponent({
  name: 'ExtraLog',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'sn',
              label: '机具号'
            },
            {
              field: 'agentId',
              label: '代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'backerNo',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo
            }
          ]
        },
        remote: {
          action: https?.market.getExtraLog
        }
      },
      cols: [
        {
          field: 'sn',
          label: '机具号'
        },
        {
          field: 'checkSerno',
          label: '考核单号'
        },
        {
          field: 'backerNo',
          label: '品牌号',
          options: mapper?.device.backerNo
        },
        {
          field: 'frzFlag',
          label: '冻结类型',
          options: mapper?.device.freezeFlag
        },
        {
          field: 'uid',
          label: '用户ID'
        },
        {
          field: 'agentId',
          label: '归属代理ID'
        },
        {
          field: 'rewardId',
          label: '返现代理ID'
        },
        {
          field: 'rewardAmt',
          label: '返现金额'
        },
        {
          field: 'triggerAmt',
          label: '达标金额'
        },
        {
          field: 'checkStatus',
          label: '考核状态',
          options: mapper?.market.checkStatus
        },
        {
          field: 'reachTime',
          label: '达标时间'
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'crtTime',
          label: '创建时间'
        },
        {
          field: 'updTime',
          label: '更新时间'
        }
      ]
    };
    return { tableOpts };
  }
});
